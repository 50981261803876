var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.platFormTitle,
        visible: _vm.dialogFormVisible,
        width: "60%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents1" },
        [
          _c(
            "el-form",
            {
              ref: "platForm",
              attrs: {
                model: _vm.platForm,
                rules: _vm.rules,
                "label-width": "auto",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "集配业务中心",
                    prop: "logistic_business_id",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "选择集配业务中心" },
                      model: {
                        value: _vm.platForm.logistic_business_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.platForm, "logistic_business_id", $$v)
                        },
                        expression: "platForm.logistic_business_id",
                      },
                    },
                    _vm._l(_vm.businessList, function (item) {
                      return _c("el-option", {
                        key: item.logisticBusinessId,
                        attrs: {
                          label: item.logisticBusinessName,
                          value: item.logisticBusinessId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogFormVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitFrom } },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }