<template>
  <!--  新增显示专区弹窗  -->
  <el-dialog
    :title="platFormTitle"
    :visible.sync="dialogFormVisible"
    width="60%"
    class="dialog"
    center
  >
    <div class="contents1">
      <el-form
        :model="platForm"
        :rules="rules"
        ref="platForm"
        label-width="auto"
      >
        <el-form-item label="集配业务中心" prop="logistic_business_id">
          <el-select
            v-model="platForm.logistic_business_id"
            clearable
            placeholder="选择集配业务中心"
          >
            <el-option
              v-for="item in businessList"
              :key="item.logisticBusinessId"
              :label="item.logisticBusinessName"
              :value="item.logisticBusinessId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitFrom">确认</el-button>
    </span>
  </el-dialog>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { postRegionConfigAdd } from "@/api/generalControl/zone";
export default {
  props: ["tableData", "businessList"],
  name: "addConfigDialog",
  data() {
    return {
      dialogFormVisible: false, //编辑弹窗
      platFormTitle: "", //弹框title
      platFormClone: {}, // 复制数据，用于初始化
      platForm: {
        logistic_business_id: "",
      },
      rules: {
        logistic_business_id: [
          {
            required: true,
            message: "请选择集配中心",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.platFormClone = cloneDeep(this.platForm);
  },
  methods: {
    /**
     *初始化数据
     */
    initData() {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs.platForm.clearValidate();
      });
    },
    /**
     *新增数据
     */
    addData() {
      this.platFormTitle = "新增配置";
      this.platForm = {
        ...this.platFormClone,
      };
      this.initData();
    },
    /**
     * 提交表单
     */
    submitFrom: debounce(function () {
      this.$refs.platForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$confirm(`是否确认${this.platFormTitle}`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(async () => {
          let logistic_business_name =
            this.businessList.find(
              (item) =>
                item.logisticBusinessId == this.platForm.logistic_business_id
            )?.logisticBusinessName || ""; // 获取选中集配中心名称
          try {
            await postRegionConfigAdd({
              ...this.platForm,
              logistic_business_name,
            });
            this.$emit("submit-form");
            this.tool.message("成功" + this.platFormTitle, "success");
          } catch (error) {
            console.error("postForceIntervene", error);
          } finally {
            this.dialogFormVisible = false;
          }
        });
      });
    }, 300),
  },
};
</script>
<style scoped lang="scss">
.contents1 {
  box-sizing: border-box;
  padding: 10px 26px 40px;
}
</style>
