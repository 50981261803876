<template>
  <div class="fundconfig-wrapper bj">
    <!-- 顶部操作 start -->
    <div class="title">
      <div class="seach order-wrapper">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="onHandleRefresh"
        ></el-button>
        <el-button
          @click="onHandleConfig('addData')"
          type="primary"
          icon="el-icon-search"
          >新增</el-button
        >
      </div>
    </div>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="80" align="center" label="序号">
        </el-table-column>
        <el-table-column
          prop="logisticBusinessName"
          align="center"
          label="集配中心"
        >
          <template slot-scope="scope">
            <span>{{ scope.row?.logisticBusinessName || "——" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="configName"
          min-width="150"
          show-overflow-tooltip
          align="center"
          label="配置"
        >
          <template slot-scope="scope">
            <span>{{ scope.row?.configName || "——" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag
              :type="
                stateList.find((item) => {
                  return item.value === (scope.row.status || 1);
                }).label == '启用'
                  ? 'success'
                  : 'warning'
              "
              >{{
                stateList.find((item) => {
                  return item.value === (scope.row.status || 1);
                }).label
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >{{
                stateList.find((item) => {
                  return item.value != (scope.row.status || 1);
                }).label
              }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 新增配置  start-->
    <add-config-dialog
      ref="addConfig"
      :tableData="tableData"
      :businessList="businessList"
      @submit-form="submitForm"
    ></add-config-dialog>
    <!-- 新增配置 end-->
  </div>
</template>
<script>
import {
  postRegionConfigList,
  postRegionConfigEdit,
  postRegionSelectConfig,
} from "@/api/generalControl/zone";
import addConfigDialog from "./modules/addConfig/index.vue";
import { STATE_ENUM } from "./utils/enum";
export default {
  name: "DisplayZone",
  components: { addConfigDialog },
  data() {
    return {
      loading: false,
      stateList: Object.values(STATE_ENUM), //状态列表
      tableData: [], //列表数据
      businessList: [], //集配中心数据
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    /**
     * 初始化数据
     */
    initData() {
      this.postAjaxRegionConfigList();
      this.ajaxGetBusinessList();
    },
    /**
     * 获得集配中心列表
     */
    async ajaxGetBusinessList() {
      try {
        const { data } = await postRegionSelectConfig();
        this.businessList = data.businessConfig;
      } catch (err) {
        console.error("ajax postRegionSelectConfig err", err);
      }
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.postAjaxRegionConfigList();
    },
    /**
     * 新增配置
     */
    onHandleConfig(func, val) {
      this.$refs.addConfig[func](val);
    },
    /**
     * 刷新列表
     */
    submitForm() {
      this.postAjaxRegionConfigList();
    },
    /**
     * 获得列表数据
     */
    async postAjaxRegionConfigList() {
      this.loading = true;
      try {
        const { data } = await postRegionConfigList({
          page: 1,
          pageSize: 999,
        });
        this.tableData = data.data;
      } catch (err) {
        console.error("ajax postRegionConfigList err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 数据启用禁用
     */
    handleEdit(row) {
      this.$confirm(
        row.status === STATE_ENUM["disabled"].value
          ? `确定启用么？`
          : `确定禁用么？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          try {
            const res = await postRegionConfigEdit({
              logistic_business_id: row.logisticBusinessId,
              status:
                row.status === STATE_ENUM["disabled"].value
                  ? STATE_ENUM["unDisabled"].value
                  : STATE_ENUM["disabled"].value,
            });
            this.$message({
              type: "success",
              message:
                row.status === STATE_ENUM["disabled"].value
                  ? "启用成功"
                  : "禁用成功",
            });
            this.submitForm();
          } catch (error) {
            console.log(error, "postSupplierUpdateState");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message:
              row.status === STATE_ENUM["disabled"].value
                ? "取消启用"
                : "取消禁用",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.fundconfig-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .order-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    background: #ffffff;
    padding: 20px;
    .inputs {
      display: flex;
      .label {
        line-height: 38px;
      }
    }
  }
  .title {
    background: #ffffff;
    box-sizing: border-box;
    display: flex;
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }
  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
}
</style>
